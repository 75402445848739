import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import RolexCategoryBtns from '../../components/preowned/RolexCategoryBtns'

// markup
const RolexOysterPerpetual = () => {
  const data = useStaticQuery(
    graphql`
      query queryRolexOysterPerpetual {
        products: allStrapiProduct(
          filter: { brand: { eq: "Rolex" }, model: { regex: "/Oyster/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Rolex Oyster Perpetual Watches for Sale'}
      canonical={'/fine-watches/rolex/oyster-perpetual/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Rolex Oyster Perpetual Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/rolex/rolex-oyster-perpetual/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-rolex-oyster-header.png"
                alt="Pre-Owned Certified Used Rolex Oyster Perpetual Watches Header"
                aria-label="Used Rolex Oyster Perpetual Watch Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">USED ROLEX OYSTER PERPETUAL </h1>
            <h2>
              ROLEX OYSTER PERPETUAL: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED ROLEX
              WATCHES{' '}
            </h2>
            <h3>BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED ROLEX OYSTER PERPETUAL WATCHES</h3>
            <p>
              <br />
              <br />
              <b>Pre-Owned Used Rolex Oyster Perpetual</b>
              <br />
              <br />
              Named after two of Rolex’s greatest innovations — the waterproof Oyster case and the
              Perpetual self-winding movement — the Oyster Perpetual is the brand’s most modest
              model. Modern references are exclusively crafted in stainless steel (Rolex made gold
              and two-tone variants in the past) and offer straightforward time-only dials. While
              pre-owned Rolex Oyster Perpetual watches were traditionally the most affordable Rolex,
              these days it’s not that simple. Thanks to a colorful array of dials, multiple sizes,
              and impeccable quality, used Rolex Oyster Perpetual watches are hot items in the
              collector’s market with high resale prices to match. We offer certified pre-owned watches.
              <br />
              <br />
              <b>Buying and Selling Used Rolex Oyster Perpetual Watches</b>
              <br />
              <br />
              According to the countless happy customers we’ve had the pleasure of serving over the
              last four decades, Gray and Sons is the #1 buyer and seller of pre-owned used Rolex
              Oyster Perpetual watches. As an independent Rolex dealer, Gray and Sons benefits from
              price flexibility and inventory control. Our
              <a href="/chat-with-rich">DECISION MAKERS </a>
              can make a deal with you on any item in our comprehensive inventory, which is home to
              plenty of secondhand Oyster Perpetual watches, in stock right now and ready to ship.
              Otherwise, if you’re wondering, “where can I sell my used Rolex Oyster Perpetual
              watch?” visit
              <a href="https://sellusyourjewelry.com">www.sellusyourjewelry.com </a> for the best
              cash offer in the market.
              <br />
              <br />
              As one of Rolex’s longest-running and most expansive collections, the variety of
              pre-owned Rolex Oyster Perpetual watches is vast. There are 41mm, 39mm, 36mm, 34mm,
              31mm, 28mm, and 26mm Oyster Perpetual models to choose from. What’s more, there are so
              many different dial colors including vibrant shades like turquoise, yellow, red,
              green, purple, and blue, as well as neutral tones like black, white, silver, and
              rhodium. The following references are some of the most popular secondhand Rolex Oyster
              Perpetual models: 124300, 126000, 124200, 277200, 276200, 114300, 114200, 177200,
              176200
              <br />
              <br />
              <b>
                Gray and Sons is the Best Source for Buying and Selling Pre-Owned Rolex Oyster
                Perpetual
              </b>
              <br />
              <br />
              Shop Gray and Sons on our website, get the best deal on Used Rolex Oyster Perpetual
              watches <a href="/fine-watches/rolex/oyster-perpetual/">HERE. </a>
              Gray and Sons is proud to have served countless customers over the last 42 years, many
              of which name us the #1 pre-owned used Rolex Oyster Perpetual buyer and seller, both
              online and offline. Along with our top-tier e-commerce website, Gray and Sons also
              operates a physical store, located next to the esteemed Bal Harbour Shops in the
              Surfside/Miami area (open six days a week.) Visit our store to browse our large
              selection of secondhand Rolex Oyster Perpetual watches or speak to one of our watch
              specialist about selling your watch. You can even have an expert watchmaker service
              your watch at our onsite independent Rolex repair facility. Gray and Sons serves the
              Miami, Miami Beach, Coral Gables, Coconut Grove, South Beach, Bal Harbour, Surfside,
              Sunny Isles, Ventura, Fort Lauderdale, Pinecrest, and Brickle areas.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/rolex-oyster-perpetual/">
                <button>SHOP ROLEX OYSTER PERPETUAL WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK ROLEX OYSTER PERPETUAL WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <section>
        <h2 className="text-center py-10">EXPLORE MORE GREAT WATCH STORIES FROM GRAY &amp; SONS</h2>
        <RolexCategoryBtns />
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default RolexOysterPerpetual
